import Vue from 'vue'
import VueRouter from 'vue-router'
import Settings from "@/views/settings/index.vue"
import Logs from "@/views/settings/Logs.vue"

import ForgotPassword from "@/views/forgotpassword.vue"
import ResetPasswordForm from "@/views/resetpassword.vue"
import Profile from "@/views/profile/index.vue"

import driverProfile from "@/views/drivers/profile.vue"
import driverInvoice from "@/views/drivers/invoices.vue"
import driverReceipt from "@/views/drivers/receipts.vue"
import driverAllInvoice from "@/views/drivers/allinvoices.vue"
import driverAllReceipt from "@/views/drivers/allreceipts.vue"
import driverRating from "@/views/drivers/ratings.vue"
import driverExpiry from "@/views/drivers/expiry.vue"
import driverDocument from "@/views/drivers/documents.vue"
import driverDisciplinary from "@/views/drivers/disciplinary.vue"

import returnBooking from "@/views/bookings/returnbooking.vue"
import pastBooking from "@/views/bookings/pastbooking.vue"
import addBookingList from "@/views/bookings/newbooking.vue"
import todayBookingList from "@/views/bookings/today-bookings.vue"
import bookingList from "@/views/bookings/index.vue"
import bookingEdit from "@/views/bookings/edit.vue"

import Reports from "@/views/reports/index.vue"
import InvoiceReport from '@/views/reports/invoice.vue'
import organisationRating from "@/views/bookings/rating.vue"

import quotationList from "@/views/finance/quotation/QuotationList.vue"
import quotationDetail from "@/views/finance/quotation/QuotationDetail.vue"
import newQuotation from "@/views/finance/quotation/NewQuotation.vue"
import showQuotation from "@/views/finance/quotation/ShowQuotation.vue"
import editQuotation from "@/views/finance/quotation/EditQuotation.vue"
import convertQuotation from "@/views/finance/quotation/ConvertQuotation.vue"

import customerStatement from "@/views/finance/statements/index.vue"
import invoiceList from "@/views/finance/invoices/index.vue"
import invoiceDetail from "@/views/finance/invoices/details.vue"
import newInvoice from "@/views/finance/invoices/new.vue"
import showInvoice from "@/views/finance/invoices/show.vue"
import editInvoice from "@/views/finance/invoices/edit.vue"
import paymentList from "@/views/finance/payments/index.vue"

import witholdingsList from "@/views/finance/tax/witholdings.vue"
import taxPaymentList from "@/views/finance/tax/payments.vue"

import newPayment from "@/views/finance/payments/new.vue"
import showPayment from "@/views/finance/payments/show.vue"
import editPayment from "@/views/finance/payments/edit.vue"
import creditnotesList from "@/views/finance/credit_notes/index.vue"
import newCreditNote from "@/views/finance/credit_notes/new.vue"
import showCreditNote from "@/views/finance/credit_notes/show.vue"
import editCreditNote from "@/views/finance/credit_notes/edit.vue"
import debitnotesList from "@/views/finance/debit_notes/index.vue"
import newDebitNote from "@/views/finance/debit_notes/new.vue"
import showDebitNote from "@/views/finance/debit_notes/show.vue"
import editDebitNote from "@/views/finance/debit_notes/edit.vue"
import paymentReversalsList from "@/views/finance/reversals/index.vue"
import newPaymentReversal from "@/views/finance/reversals/new.vue"
import editPaymentReversal from "@/views/finance/reversals/edit.vue"

import clientsList from "@/views/clients/index.vue"

import Dashboard from "@/views/Dashboard.vue"
import Login from "@/views/Login.vue"
import Signup from "@/views/Signup.vue"

import orgTypeList from "@/views/org/type/index.vue"
import orgCategoryList from "@/views/org/category/index.vue"
import orgProjectList from "@/views/org/projects/index.vue"
import orgList from "@/views/org/list/index.vue"
import organisationRates from "@/views/org/rates/index.vue"

import vehicleMakeList from "@/views/vehicle/make/index.vue"
import vehicleTypeList from "@/views/vehicle/type/index.vue"
import vehicleOwnershipList from "@/views/vehicle/ownership/index.vue"
import vehicleList from "@/views/vehicle/list/index.vue"
import vehicleModelList from "@/views/vehicle/model/index.vue"

import customerList from "@/views/customers/index.vue"
import driverList from "@/views/drivers/index.vue"
import usersList from "@/views/config/users.vue"
import roleList from "@/views/config/roles.vue"
import permissionList from "@/views/config/permissions.vue"

import EntranceList from "@/views/expressway/entrance.vue"
import ExitList from "@/views/expressway/exit.vue"
import RateList from "@/views/expressway/rate.vue"

Vue.use(VueRouter)

const routes = [
 { 
    path: '/expressway/entrances', 
    name: 'EntranceList', 
    component: EntranceList, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/expressway/exits', 
    name: 'ExitList', 
    component: ExitList, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/expressway/rates', 
    name: 'RateList', 
    component: RateList, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/settings', 
    name: 'Settings', 
    // component: () => import('../views/settings/index.vue'), 
    component: Settings, 
    meta: {
      auth: true
    }
  },
  // logs
  { 
    path: '/logs', 
    name: 'Logs', 
    component: Logs, 
    meta: {
      auth: true
    }
  },
  { 
    path: '/forgot/password', 
    name: 'ForgotPassword', 
    component: ForgotPassword, 
    // component: () => import('../views/forgotpassword.vue'), 
    meta: {
      auth: undefined
    }
  },
  { 
    path: '/reset/:token', 
    name: 'ResetPasswordForm', 
    component: ResetPasswordForm, 
    // component: () => import('../views/resetpassword.vue'), 
    meta: {
      auth: undefined
    } 
  },
  {
    path: '/user/profile',
    name: 'Profile',
    component: Profile,
    // component: () => import('../views/profile/index.vue'),
    meta: {
      auth: true
    }
  },

  {
      name: 'driverProfile',
      path: '/driver/:id/profile',
      component: driverProfile,
      // component: () => import('../views/drivers/profile.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'driverAllInvoice',
      path: '/drivers/invoices',
      component: driverAllInvoice,
      meta: {
        auth: true
      }
  },
  {
      name: 'driverAllReceipt',
      path: '/drivers/receipts',
      component: driverAllReceipt,
      meta: {
        auth: true
      }
  },
  {
      name: 'driverInvoice',
      path: '/driver/:id/invoices',
      component: driverInvoice,
      meta: {
        auth: true
      }
  },
  {
      name: 'driverReceipt',
      path: '/driver/:id/payments',
      component: driverReceipt,
      meta: {
        auth: true
      }
  },
  {
      name: 'driverRating',
      path: '/driver/:id/ratings',
      component: driverRating,
      meta: {
        auth: true
      }
  },
  {
      name: 'driverDocument',
      path: '/driver/:id/documents',
      component: driverDocument,
      meta: {
        auth: true
      }
  },
  {
      name: 'driverExpiry',
      path: '/driver/:id/psv-expiry',
      component: driverExpiry,
      meta: {
        auth: true
      }
  },
  {
      name: 'driverDisciplinary',
      path: '/driver/:id/disciplinaries',
      component: driverDisciplinary,
      meta: {
        auth: true
      }
  },

  {
    path: '/past/booking',
    name: 'pastBooking',
    component: pastBooking,
    // component: () => import('../views/bookings/pastbooking.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/new/return/booking',
    name: 'returnBooking',
    component: returnBooking,
    // component: () => import('../views/bookings/returnbooking.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/new/booking',
    name: 'addBookingList',
    component: addBookingList,
    // component: () => import('../views/bookings/newbooking.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/today-booking/list',
    name: 'todayBookingList',
    component: todayBookingList,
    // component: () => import('../views/bookings/today-bookings.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/booking/list',
    name: 'bookingList',
    component: bookingList,
    // component: () => import('../views/bookings/index.vue'),
    meta: {
      auth: true
    }
  },
  {
      name: 'bookingEdit',
      path: '/booking/:id/edit',
      component: bookingEdit,
      // component: () => import('../views/bookings/edit.vue'),
      meta: {
        auth: true
      }
  },
  {
    path: '/booking/reports',
    name: 'Reports',
    component: Reports,
    // component: () => import('../views/reports/index.vue'),
    meta: {
      auth: true
    }
    // beforeEnter: (to, from, next) => {
    //     if(ability.can('view-reports', 'all')) {
    //       next()
    //       return
    //     }else {
    //       next(false)
    //       return
    //     } 
        
    //   }
  },
  {
    path: '/booking/invoice',
    name: 'InvoiceReport',
    component: InvoiceReport,
    meta: {
      auth: true
    }
  },
  // organisation rating
  {
    path: '/organisation/ratings',
    name: 'organisationRating',
    component: organisationRating,
    // component: () => import('../views/bookings/rating.vue'),
    meta: {
      auth: true
    }
  },
  // statements
  {
    path: '/organisation/statements',
    name: 'customerStatement',
    component:customerStatement,
    // component: () => import('../views/finance/statements/index.vue'),
    meta: {
      auth: true
    }
  },
  // invoices
  {
    path: '/organisation/invoices',
    name: 'invoiceList',
    component: invoiceList,
    // component: () => import('../views/finance/invoices/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/invoice-summary',
    name: 'invoiceDetail',
    component:invoiceDetail,
    // component: () => import('../views/finance/invoices/details.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/invoice',
    name: 'newInvoice',
    component:newInvoice,
    // component: () => import('../views/finance/invoices/new.vue'),
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showInvoice',
      path: '/organisation/invoice/:id/details',
      component: showInvoice,
      // component: () => import('../views/finance/invoices/show.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'editInvoice',
      path: '/organisation/invoice/:id/edit',
      component: editInvoice,
      // component: () => import('../views/finance/invoices/edit.vue'),
      meta: {
        auth: true
      }
  },
  // end invoices

  // payment
  {
    path: '/organisation/witholdings',
    name: 'witholdingsList',
    component:witholdingsList,
    // component: () => import('../views/finance/tax/witholdings.vue'),
    meta: {
      auth: true
    }
  }, 
  // witholding payment
  {
    path: '/organisation/witholding-payment',
    name: 'taxPaymentList',
    component:taxPaymentList,
    // component: () => import('../views/finance/tax/payments.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/payments',
    name: 'paymentList',
    component:paymentList,
    // component: () => import('../views/finance/payments/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/payment',
    name: 'newPayment',
    component: newPayment,
    // component: () => import('../views/finance/payments/new.vue'),
    meta: {
      auth: true
    }
  }, 

   {
      name: 'showPayment',
      path: '/organisation/payment/:id/details',
      component: showPayment,
      // component: () => import('../views/finance/payments/show.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'editPayment',
      path: '/organisation/payment/:id/edit',
      component:editPayment,
      // component: () => import('../views/finance/payments/edit.vue'),
      meta: {
        auth: true
      }
  },
  // end payments

  // credit notes
  {
    path: '/organisation/credit-notes',
    name: 'creditnotesList',
    component:creditnotesList,
    // component: () => import('../views/finance/credit_notes/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/credit-note',
    name: 'newCreditNote',
    component: newCreditNote,
    // component: () => import('../views/finance/credit_notes/new.vue'),
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showCreditNote',
      path: '/organisation/credit-note/:id/details',
      component: showCreditNote,
      // component: () => import('../views/finance/credit_notes/show.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'editCreditNote',
      path: '/organisation/credit-note/:id/edit',
      component: editCreditNote,
      // component: () => import('../views/finance/credit_notes/edit.vue'),
      meta: {
        auth: true
      }
  },
  // end credit notes

  // debit notes
  {
    path: '/organisation/debit-notes',
    name: 'debitnotesList',
    component:debitnotesList,
    // component: () => import('../views/finance/debit_notes/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/debit-note',
    name: 'newDebitNote',
    component:newDebitNote,
    // component: () => import('../views/finance/debit_notes/new.vue'),
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showDebitNote',
      path: '/organisation/debit-note/:id/details',
      component:showDebitNote,
      // component: () => import('../views/finance/debit_notes/show.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'editDebitNote',
      path: '/organisation/debit-note/:id/edit',
      component: editDebitNote,
      // component: () => import('../views/finance/debit_notes/edit.vue'),
      meta: {
        auth: true
      }
  },
  // end credit notes

  // reversal payments
  {
    path: '/organisation/payment-reversals',
    name: 'paymentReversalsList',
    component: paymentReversalsList,
    // component: () => import('../views/finance/reversals/index.vue'),
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/reversal',
    name: 'newPaymentReversal',
    component:newPaymentReversal,
    // component: () => import('../views/finance/reversals/new.vue'),
    meta: {
      auth: true
    }
  },
  {
      name: 'editPaymentReversal',
      path: '/organisation/payment-reversal/:id/edit',
      component: editPaymentReversal,
      // component: () => import('../views/finance/reversals/edit.vue'),
      meta: {
        auth: true
      }
  },
  // end reversal payments

  {
    path: '/clients/list',
    name: 'clientsList',
    component: clientsList,
    // component: () => import('../views/clients/index.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    // component: () => import('../views/Dashboard.vue'),
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    // component: () => import('../views/Login.vue'),
    meta: {
      auth: undefined
    }
  },
  {
    path: '/auth/register',
    name: 'Signup',
    component: Signup
    // component: () => import('../views/Signup.vue')
  },
  {
      name: 'orgTypeList',
      path: '/organisation/type/list',
      component: orgTypeList,
      // component: () => import('../views/org/type/index.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'orgCategoryList',
      path: '/organisation/category/list',
      component: orgCategoryList,
      // component: () => import('../views/org/category/index.vue'),
      meta: {
        auth: true
      }
  },

  {
      name: 'orgProjectList',
      path: '/organisation/projects/list',
      component: orgProjectList,
      // component: () => import('../views/org/projects/index.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'orgList',
      path: '/organisation/list',
      component:orgList,
      // component: () => import('../views/org/list/index.vue'),
      meta: {
        auth: true
      }
  },

  {
    path: '/organisation/rates',
    name: 'organisationRates',
    component:organisationRates,
    // component: () => import('../views/org/rates/index.vue'),
    meta: {
      auth: true
    }
  },

// vehicles make
  {
      name: 'vehicleMakeList',
      path: '/vehicle/make/list',
      component:vehicleMakeList,
      // component: () => import('../views/vehicle/make/index.vue'),
      meta: {
        auth: true
      }

  },
  // vehicles type
  {
      name: 'vehicleTypeList',
      path: '/vehicle/type/list',
      component: vehicleTypeList,
      // component: () => import('../views/vehicle/type/index.vue'),
      meta: {
        auth: true
      }

  },
  // vehicles ownership
  {
      name: 'vehicleOwnershipList',
      path: '/vehicle/ownership/list',
      component:vehicleOwnershipList,
      // component: () => import('../views/vehicle/ownership/index.vue'),
      meta: {
        auth: true
      }

  },
  // vehicles list
  {
      name: 'vehicleList',
      path: '/vehicle/list',
      component: vehicleList,
      // component: () => import('../views/vehicle/list/index.vue'),
      meta: {
        auth: true
      },
  },
  // vehicles model
  {
      name: 'vehicleModelList',
      path: '/vehicle/model/list',
      component:vehicleModelList,
      // component: () => import('../views/vehicle/model/index.vue'),
      meta: {
        auth: true
      }
  },
  // customers
  {
      name: 'customerList',
      path: '/customers/list',
      component:customerList,
      // component: () => import('../views/customers/index.vue'),
      meta: {
        auth: true
      }
  },
  // drivers
  {
      name: 'driverList',
      path: '/drivers/list',
      component:driverList,
      // component: () => import('../views/drivers/index.vue'),
      meta: {
        auth: true
      }
  },
  // users
  {
      name: 'usersList',
      path: '/users/list',
      component:usersList,
      // component: () => import('../views/config/users.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'roleList',
      path: '/role/list',
      component: roleList,
      // component: () => import('../views/config/roles.vue'),
      meta: {
        auth: true
      }
  },
  {
      name: 'permissionList',
      path: '/permission/list',
      component:permissionList,
      // component: () => import('../views/config/permissions.vue'),
      meta: {
        auth: true
      }
  },
  // quotation
  {
    path: '/organisation/quotations',
    name: 'quotationList',
    component: quotationList,
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/quotation-details',
    name: 'quotationDetail',
    component:quotationDetail,
    meta: {
      auth: true
    }
  }, 
  {
    path: '/organisation/new/quotation',
    name: 'newQuotation',
    component:newQuotation,
    meta: {
      auth: true
    }
  }, 
   {
      name: 'showQuotation',
      path: '/organisation/quotation/:id/details',
      component: showQuotation,
      meta: {
        auth: true
      }
  },
  {
      name: 'editQuotation',
      path: '/organisation/quotation/:id/edit',
      component: editQuotation,
      meta: {
        auth: true
      }
  },
  {
      name: 'convertQuotation',
      path: '/organisation/quotation/:id/convert',
      component: convertQuotation,
      meta: {
        auth: true
      }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    next('/login')
    return
  }else {
    next()
  }
})

export default router
