<template>
  <v-app>
    <v-app-bar app elevation="0" class="appbar" color="dafault" v-if="isLogged">
      <router-link link :to="{name: 'Dashboard'}" style="text-decoration:none !important;color:inherit !important;margin:0 !important;padding:0 !important;line-height:0 !important">
        <div style="background: rgb(255, 255, 255);height: 56px;"><img src="@/assets/logo.jpeg" class="ml-3" style="width:65% !important;margin-top:.1rem"></div>
      </router-link>
      <v-spacer></v-spacer>
      <v-list-item>
        <v-list-item-content>
          <!-- <v-list-item-title class="white--text">{{ $store.state.user.user.name }}</v-list-item-title> -->
          <v-list-item-title><div v-if="$store.state.user.user.organisation !== null" style="text-transform:uppercase !important" class="white--text">{{ $store.state.user.user.organisation.name }}</div> </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-menu offset-x left bottom origin="center center" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon>
                <v-icon color="grey lighten-1" v-bind="attrs" v-on="on">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            
            <v-list-item-group
              color="primary"
              class="actions"
              >

                <v-list-item
                  @click="logout()"
                >
                    <v-list-item-icon>
                      <v-icon color="#000000">mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <span>Logout</span>
                    </v-list-item-content>
                </v-list-item>

            </v-list-item-group>
          </v-menu>

        </v-list-item-action>

      </v-list-item>
    </v-app-bar>
    <v-navigation-drawer
      v-if="isLogged"
      v-model="mainSidebarDrawer"
      permanent
      app
      expand-on-hover
      width="235"
      @transitionend="collapseSubItems"
      >
      <v-list-item class="atitle" v-if="isLogged">
          <v-list-item-avatar>
              <v-icon>mdi-account-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="text-truncate font-weight-bold">
              {{ $store.state.user.user.name }}
          </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list class="sidenav" v-if="isLogged">
        <!-- first list group -->
        <v-list-group
          v-for="item in business"
          :key="item.title"
          no-action
          >
          <template v-slot:activator>
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" class="font-weight-medium"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            link 
            :to="subItem.href"
          >
            <!-- <v-list-item-content> -->
                <v-list-item-title class="black--text" v-text="subItem.title"></v-list-item-title>
                <v-list-item-icon>
                  <v-avatar
                    color="rgba(0,0,0,.05)"
                    size="25"
                  >
                    <span class="black--text" v-text="subItem.icon"></span>
                  </v-avatar>
                </v-list-item-icon>
            <!-- </v-list-item-content> -->
            
          </v-list-item>
          <v-divider></v-divider>
        </v-list-group>
        <!-- end first group -->

        <!-- group 2 -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-icon>
                <v-icon>mdi-office-building-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">Business Config</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-group
            v-for="item in businessconfig"
            :key="item.title"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" class="font-weight-medium"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subItem in item.items"
              :key="subItem.title"
              link 
              :to="subItem.href"
            >
              <!-- <v-list-item-content> -->
                <v-list-item-title class="black--text" v-text="subItem.title"></v-list-item-title>
                <v-list-item-icon>
                  <v-avatar
                    color="rgba(0,0,0,.05)"
                    size="25"
                  >
                    <span class="black--text" v-text="subItem.icon"></span>
                  </v-avatar>
                </v-list-item-icon>
              <!-- </v-list-item-content> -->
            </v-list-item>
            <v-divider></v-divider>
          </v-list-group>
        </v-list-group>
        <!-- end group 2 -->

        <!-- third list group -->
        <v-list-group
          v-for="item in reports"
          :key="item.title"
          no-action
          >
          <template v-slot:activator>
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" class="font-weight-medium"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            link 
            :to="subItem.href"
          >
            <!-- <v-list-item-content> -->
              <v-list-item-title class="black--text" v-text="subItem.title"></v-list-item-title>
              <v-list-item-icon>
                  <v-avatar
                    color="rgba(0,0,0,.05)"
                    size="25"
                  >
                    <span class="black--text" v-text="subItem.icon"></span>
                  </v-avatar>
                </v-list-item-icon>
            <!-- </v-list-item-content> -->
          </v-list-item>
          <v-divider></v-divider>
        </v-list-group>
        <!-- end third group -->
      </v-list>
    </v-navigation-drawer>
    <v-main :class= "[isLogged ? '' : 'authpadding']">
        <v-container fluid class="pt-5 appcontent px-2">
            <v-row>
                <v-col cols="12" style="margin-top:1.3rem !important;padding: 12px 3px;">
                  <!-- <transition name="fade"> -->
                        <router-view></router-view>
                    <!-- </transition> -->
                </v-col>
            </v-row>
        </v-container>
    </v-main>
    <!-- <v-main>
      <router-view/>
    </v-main> -->
  </v-app>
</template>
<style>
  .v-main.authpadding{
    padding: 0 !important;
  }
  .v-main{
    padding: 56px 0px 0px 56px !important;
  }
  .appbar .v-list-item{
    flex:0 !important;
  }
  .appbar{
      left:56px !important;
  }
  .v-toolbar__content, .v-toolbar__extension {
      padding: 0 !important;
  }
  .appbar,.v-toolbar__content{
    height:56px !important;
  }
  .v-navigation-drawer{
    width: 226px;
  }
  .v-navigation-drawer__content .v-avatar.v-list-item__avatar{
    height: 40px !important;
    min-width: 0 !important;
    width: 40px !important;
  }
  .appcontent {
    background: var(--ab-background) !important;
  }
  .v-toolbar__content {
    background: var(--ab-primary) !important;
  }
</style>
<script>
import { mapGetters } from 'vuex'
import Pusher from 'pusher-js'
let pusher = new Pusher('c70815fa280f47b78ab9', {
          cluster: 'ap2'
        });

export default {
  name: 'App',

  data: () => ({
    business: [
      {
        title: 'Business',
        icon: 'mdi-domain',
        color: '#333333',
        active:false,
        items: [
          { title:"All Bookings", href:"/booking/list",icon:"B"},
          { title:"Today Bookings", href:"/today-booking/list",icon:"B"},
          { title:"Add Booking", href:"/new/booking",icon:"AB"},
          { title:"Return Booking", href:"/new/return/booking",icon:"RB"},
          { title:"Past Booking", href:"/past/booking",icon:"PB"},
          { title:"Organisation Rating", href:"/organisation/ratings",icon:"OR"}
        ]
      }
    ],
    businessconfig: [
      {
        title: 'Organisations',
        color: '#333333',
        active:false,
        items: [
          { title:"List", href:"/organisation/list",icon:"OL"},
          { title:"Projects", href:"/organisation/projects/list",icon:"OP"},
          { title:"Categories", href:"/organisation/category/list",icon:"OC"},
          { title:"Types", href:"/organisation/type/list",icon:"OT"},
          { title:"Rates", href:"/organisation/rates",icon:"R"}
        ]
      },
      {
        title: 'Vehicles',
        icon: 'mdi-taxi', 
        color: '#333333',
        active:false,
        items: [
          { title:"List", href:"/vehicle/list",icon:"VL"},
          { title:"Makes", href:"/vehicle/make/list",icon:"VM"},
          { title:"Types", href:"/vehicle/type/list",icon:"VT"},
          { title:"Models", href:"/vehicle/model/list",icon:"VM"},
          { title:"Ownerships", href:"/vehicle/ownership/list",icon:"VO"}
        ]
      }
    ],
    reports: [
      {
        title: 'Drivers',
        icon: 'mdi-badge-account-outline', 
        color: '#333333',
        active:false,
        items: [
          { title:"Driver List", href:"/drivers/list",icon:"DL"},
          { title:"Invoices", href:"/drivers/invoices",icon:"INV"},
          { title:"Receipts", href:"/drivers/receipts",icon:"RCT"},
        ]
      },
      {
        title: 'Finance',
        icon: 'mdi-finance', 
        color: '#333333',
        active:false,
        items: [
          { title:"Quotations", href:"/organisation/quotations",icon:"Qot"},
          { title:"Invoices", href:"/organisation/invoices",icon:"INV"},
          { title:"Invoice summary", href:"/organisation/invoice-summary",icon:"IS"},
          { title:"Payments", href:"/organisation/payments",icon:"P"},
          { title:"Witholding Tax", href:"/organisation/witholdings",icon:"WT"},
          { title:"Reversals", href:"/organisation/payment-reversals",icon:"R"},
          { title:"Credit Notes", href:"/organisation/credit-notes",icon:"CN"},
          { title:"Debit Notes", href:"/organisation/debit-notes",icon:"DN"},
          { title:"Customer Statement", href:"/organisation/statements",icon:"CS"},
        ]
      },
      {
        title: 'Reports',
        icon: 'mdi-chart-bar',
        color: '#333333',
        active:false,
        items: [
          // { title:"Full Report", href:"/booking/reports",icon:"FR"},
          { title:"Invoice Report", href:"/booking/invoice",icon:"INV"},
          // { title:"Vouchers", href:"/booking/vouchers",icon:"V"},
        ]
      },
      {
        title: 'User Config',
        icon: 'mdi-account-cog-outline', 
        color: '#333333',
        active:false,
        items: [
          { title:"User Accounts", href:"/users/list",icon:"UA"},
          { title:"Roles", href:"/role/list",icon:"R"},
          { title:"Permissions", href:"/permission/list",icon:"P"},
          { title:"Customers", href:"/customers/list",icon:"C"}
        ]
      },
      {
        title: 'Expressway',
        icon: 'mdi-highway', 
        color: '#333333',
        active:false,
        items: [
          { title:"Entrances", href:"/expressway/entrances",icon:"E"},
          { title:"Exits", href:"/expressway/exits",icon:"E"},
          { title:"Rates", href:"/expressway/rates",icon:"R"},
        ]
      },
      {
        title: 'Configs',
        icon: 'mdi-cog-outline', 
        color: '#333333',
        active:false,
        items: [
          { title:"Settings", href:"/settings",icon:"S"},
          { title:"Logs", href:"/logs",icon:"L"},
        ]
      }
    ],
    mainSidebarDrawer: true,
    mini: true,
  }),
  computed: {
    ...mapGetters([
      'isLogged'
    ])
  },
  mounted() {
    this.pusher()
  },
  created(){
    this.$once("hook:beforeDestroy", () => {
          pusher.unsubscribe('newcustomer');
        });
  },
  methods: {
    pusher() {

        // add customer
        var channel = pusher.subscribe('newcustomer');
        channel.bind('newcustomer', data => {
            this.$store.dispatch('newCustomer', data.data)
        })

      },
    collapseSubItems() {
        this.business.map((item)=>item.active=false)
        this.businessconfig.map((item)=>item.active=false)
        this.reports.map((item)=>item.active=false)
    },
    logout () {
      this.$store.dispatch('logout')
    }
  },
};
</script>
